import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Avatar,
  Paper,
  IconButton,
  Chip,
  AvatarGroup,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Autocomplete,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  ContentCopy as CopyIcon,
  History as HistoryIcon,
  Delete as DeleteIcon,
  FileCopy as DuplicateIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Issue, User, Conversation, Project, Task } from '../types';
import FileUploadArea from './FileUploadArea';
import { Editor } from '@tinymce/tinymce-react';
import { initTinyMCE } from '../utils/tinymceSetup';
import useLocalStorage from '../hooks/useLocalStorage';
import { format } from 'date-fns';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { fileService } from '../fileService';
import CongratulationsLightbox from './CongratulationsLightbox';
import { notificationsService } from '../services/notificationsService';
import { issueService } from '../issueService';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import UpdateHistoryLightbox from './UpdateHistoryLightbox';
import { IssueUpdate } from '../types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog as ImageDialog, DialogContent as ImageDialogContent } from '@mui/material';

interface IssueDetailsProps {
  issue: Issue;
  onUpdateIssue: (updatedIssue: Issue) => Promise<void>;
  onDuplicateIssue: (issueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'>) => Promise<Issue>;
  users: User[];
  currentUser: User;
  onClose: () => void;
  canEdit: boolean;
  projects: Project[];
  allTags: string[];
}

const IssueDetails: React.FC<IssueDetailsProps> = ({
  issue,
  onUpdateIssue,
  onDuplicateIssue,
  users,
  currentUser,
  onClose,
  canEdit,
  projects,
  allTags,
}) => {
  const [editedIssue, setEditedIssue] = useState<Issue>({...issue});
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editedCommentContent, setEditedCommentContent] = useState('');

  const [newComment, setNewComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHeaderSticky, setIsHeaderSticky] = useLocalStorage<boolean>('issueHeaderSticky', false);
  const [newTask, setNewTask] = useState('');
  const [tasks, setTasks] = useState<Task[]>(
    issue.tasks?.map((task, index) => ({ 
      id: task.id || `task-${index}`, 
      text: task.text, 
      completed: task.completed, 
      editedAt: task.editedAt,
      createdOn: task.createdOn || new Date().toISOString(),
      completedOn: task.completedOn,
    })) || []
  );
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const [attachments, setAttachments] = useState<Array<{ url: string, metadata: { fileName: string, fileSize: number, contentType: string } }>>([]);
  const [uploadingAttachments, setUploadingAttachments] = useState<File[]>([]);
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  const [editedTaskText, setEditedTaskText] = useState('');
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<'task' | 'issue' | 'attachment'>('task');
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  const [duplicateTitle, setDuplicateTitle] = useState(`Copy - ${issue.title}`);
  const [duplicateOptions, setDuplicateOptions] = useState({
    description: true,
    selections: true,
    attachments: true,
    subTasks: true,
  });
  const [duplicateSelections, setDuplicateSelections] = useState({
    type: issue.type,
    status: issue.status,
    level: issue.level,
    assignedTo: issue.assignedTo || [],
    projectId: issue.projectId,
    tags: issue.tags || [],
  });

  const [isDuplicating, setIsDuplicating] = useState(false);
  const [duplicateSuccess, setDuplicateSuccess] = useState(false);
  const [newIssueId, setNewIssueId] = useState<string | null>(null);

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [updateHistory, setUpdateHistory] = useState<IssueUpdate[]>([]);

  const [selectedAttachment, setSelectedAttachment] = useState<{ url: string, metadata: { fileName: string } } | null>(null);

  const creator = users.find(u => u.userId === issue.createdBy);
  const assignedUsers = users.filter(u => editedIssue.assignedTo && editedIssue.assignedTo.includes(u.userId || ''));

  const activeProjects = projects.filter(project => !project.isArchived);

  useEffect(() => {
    const loadAttachments = async () => {
      const loadedAttachments = await Promise.all(
        issue.attachments.map(async (attachment) => {
          if (typeof attachment === 'string') {
            const metadata = await fileService.getFileMetadata(attachment);
            return { url: attachment, metadata };
          } else {
            return { 
              url: URL.createObjectURL(attachment), 
              metadata: { 
                fileName: attachment.name, 
                fileSize: attachment.size, 
                contentType: attachment.type 
              } 
            };
          }
        })
      );
      setAttachments(loadedAttachments);
    };
    loadAttachments();
  }, [issue.attachments]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = e.target;
    setEditedIssue(prev => ({ 
      ...prev, 
      [name]: name === 'assignedTo' ? (Array.isArray(value) ? value : [value]) : value 
    }));
  };

  const handleTagChange = (_: React.SyntheticEvent, newValue: string[]) => {
    setEditedIssue(prev => ({ ...prev, tags: newValue }));
  };

  const handleEditorChange = (content: string) => {
    setEditedIssue(prev => ({ ...prev, description: content }));
  };

  const handleCommentEditorChange = (content: string) => {
    setNewComment(content);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!canEdit) {
      return;
    }
    setIsSubmitting(true);
    try {
      const updatedIssue: Issue = {
        ...editedIssue,
        tasks: tasks.map(task => ({ id: task.id, text: task.text, completed: task.completed, createdOn: task.createdOn, editedAt: task.editedAt, completedOn: task.completedOn })),
        tags: editedIssue.tags,
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
      };
      await onUpdateIssue(updatedIssue);
      
      // Create notification for issue update
      await notificationsService.createIssueUpdateNotification(
        updatedIssue.id,
        updatedIssue.projectId,
        currentUser.userId || '',
        `${currentUser.firstName} ${currentUser.lastName}`,
        updatedIssue.title,
        'Issue updated'
      );

      setEditedIssue(updatedIssue);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating issue:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setEditedIssue(issue);
    setIsEditing(false);
  };

  const handleAddComment = async () => {
    if (newComment.trim() && currentUser.permissions?.canEditIssues) {
      const newConversation: Omit<Conversation, 'id'> = {
        message: newComment,
        timestamp: new Date().toISOString(),
        userId: currentUser.userId || '',
        userName: `${currentUser.firstName} ${currentUser.lastName}`.trim() || 'Unknown User',
      };
      try {
        const updatedIssue = await issueService.addConversation(issue.id, newConversation);
        setEditedIssue(updatedIssue);
        setNewComment('');
      } catch (error) {
        console.error("Error adding comment:", error);
      }
    }
  };

  const handleFileUpload = async (files: File[]) => {
    if (!canEdit) {
      return;
    }
    setUploadingAttachments(files);
    setIsSubmitting(true);
    try {
      let updatedIssue = { ...editedIssue };
      for (const file of files) {
        updatedIssue = await issueService.addAttachment(issue.id, file);
      }
      setEditedIssue(updatedIssue);
      // Update the attachments state
      const newAttachments = await Promise.all(updatedIssue.attachments.map(async (attachment) => {
        if (typeof attachment === 'string') {
          const metadata = await fileService.getFileMetadata(attachment);
          return { 
            url: attachment, 
            metadata: metadata
          };
        }
        return {
          url: URL.createObjectURL(attachment),
          metadata: {
            fileName: attachment.name,
            fileSize: attachment.size,
            contentType: attachment.type
          }
        };
      }));
      setAttachments(newAttachments);
    } catch (error) {
      console.error("Error adding attachments:", error);
    } finally {
      setIsSubmitting(false);
      setUploadingAttachments([]);
    }
  };

  const formatEditTime = (timestamp: string) => {
    return format(new Date(timestamp), 'MMM d, yyyy, h:mm a');
  };

  const handleRemoveAttachment = (index: number) => {
    const attachmentToRemove = attachments[index];
    if ('url' in attachmentToRemove) {
      handleDeleteWarning('attachment', attachmentToRemove.url);
    } else {
      console.warn('Attempted to delete an attachment without a URL.');
    }
  };

  const getChipColor = (value: string) => {
    const colorMap: { [key: string]: string } = {
      'Issue': '#FFB347',
      'Enhancement': '#87CEEB',
      'Open': '#ADD8E6',
      'In Process': '#E6E6FA',
      'Needs Info': '#FAFAD2',
      'Not Correct': '#FFA07A',
      'Completed': '#98FB98',
      'Blocking': '#FFC0CB',
      'Not Blocking': '#FAFAD2',
      'Design': '#A9A9A9',
      'Functionality': '#87CEFA',
      'Backend': '#DDA0DD',
    };
    return colorMap[value] || '#E0E0E0';
  };

  const handlePinClick = () => {
    setIsHeaderSticky(prev => !prev);
  };

  const handleEditComment = (commentId: string, content: string) => {
    console.log('Editing comment:', commentId, content);
    console.log('Current user ID:', currentUser.userId);
    const comment = editedIssue.conversations.find(conv => conv.id === commentId);
    console.log('Found comment:', comment);
    if (comment && comment.userId === currentUser.userId) {
      setEditingCommentId(commentId);
      setEditedCommentContent(content);
    } else {
      console.log('Comment not editable by current user');
    }
  };

  const handleSaveEditedComment = async (commentId: string) => {
    if (editedCommentContent.trim()) {
      try {
        const updatedIssue = await issueService.editConversation(issue.id, commentId, editedCommentContent);
        setEditedIssue(updatedIssue);
        setEditingCommentId(null);
        setEditedCommentContent('');
      } catch (error) {
        console.error("Error updating comment:", error);
      }
    }
  };

  const handleAddTask = async () => {
    if (newTask.trim()) {
      const newTaskItem: Omit<Task, 'id'> = {
        text: newTask.trim(),
        completed: false,
        createdOn: new Date().toISOString(),
        completedOn: undefined,
        editedAt: undefined
      };
      try {
        const updatedIssue = await issueService.addTask(issue.id, newTaskItem);
        setEditedIssue(updatedIssue);
        setTasks(updatedIssue.tasks); // Update the tasks state
        setNewTask('');
      } catch (error) {
        console.error("Error adding task:", error);
      }
    }
  };

  const handleEditTask = (taskId: string, taskText: string) => {
    setEditingTaskId(taskId);
    setEditedTaskText(taskText);
  };

  const handleSaveEditedTask = async (taskId: string) => {
    if (editedTaskText.trim()) {
      try {
        const updatedTask: Partial<Task> = {
          text: editedTaskText.trim(),
          editedAt: new Date().toISOString()
        };
        const updatedIssue = await issueService.editTask(issue.id, taskId, updatedTask);
        setEditedIssue(updatedIssue);
        setTasks(updatedIssue.tasks);
        setEditingTaskId(null);
        setEditedTaskText('');
      } catch (error) {
        console.error("Error editing task:", error);
      }
    }
  };

  const handleCopyTask = (taskText: string) => {
    navigator.clipboard.writeText(taskText);
  };

  const handleDeleteWarning = (type: 'task' | 'issue' | 'attachment', id?: string) => {
    setDeleteType(type);
    setItemToDelete(id || null);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteDialogOpen(false);
    if (deleteType === 'task' && itemToDelete) {
      try {
        const updatedTasks = tasks.filter(task => task.id !== itemToDelete);
        setTasks(updatedTasks);
        await saveTaskChanges(updatedTasks);
        setSnackbarMessage('Task deleted successfully');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error("Error deleting task:", error);
        setSnackbarMessage('Error deleting task');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    } else if (deleteType === 'issue') {
      try {
        const updatedIssue = {
          ...editedIssue,
          deletedAt: new Date().toISOString(),
          isDeleted: true,
          deletedBy: currentUser.userId || '',
          deletedByName: `${currentUser.firstName} ${currentUser.lastName}`,
        };
        await onUpdateIssue(updatedIssue);
        onClose(); // Close the issue details after deletion
      } catch (error) {
        console.error("Error deleting issue:", error);
        setSnackbarMessage('Error deleting issue');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else if (deleteType === 'attachment' && itemToDelete) {
      try {
        await issueService.deleteAttachment(issue.id, itemToDelete);
        const updatedAttachments = editedIssue.attachments.filter((attachment) => {
          if (typeof attachment === 'string') {
            return attachment !== itemToDelete;
          } else if ('url' in attachment) {
            return attachment.url !== itemToDelete;
          }
          return true; // Keep attachments that don't match the criteria
        });
        const updatedIssue = {
          ...editedIssue,
          attachments: updatedAttachments,
        };
        setEditedIssue(updatedIssue);
        await onUpdateIssue(updatedIssue);
        setSnackbarMessage('Attachment deleted successfully');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error("Error deleting attachment:", error);
        setSnackbarMessage('Error deleting attachment');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDeleteTask = (taskId: string) => {
    handleDeleteWarning('task', taskId);
  };

  const handleDeleteIssue = () => {
    handleDeleteWarning('issue');
  };

  const handleToggleTask = async (taskId: string) => {
    const updatedTasks = tasks.map(task => 
      task.id === taskId ? { 
        ...task, 
        completed: !task.completed,
        completedOn: !task.completed ? new Date().toISOString() : undefined
      } : task
    );
    setTasks(updatedTasks);
    await saveTaskChanges(updatedTasks);

    const allTasksCompleted = updatedTasks.every(task => task.completed);
    if (allTasksCompleted && updatedTasks.length > 0) {
      setShowCongratulations(true);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTasks(items);
    await saveTaskChanges(items);
  };

  const saveTaskChanges = async (updatedTasks: typeof tasks) => {
    try {
      const updatedIssue: Issue = {
        ...editedIssue,
        tasks: updatedTasks.map(task => ({ id: task.id, text: task.text, completed: task.completed, createdOn: task.createdOn, editedAt: task.editedAt, completedOn: task.completedOn })),
      };
      await onUpdateIssue(updatedIssue);
      setEditedIssue(updatedIssue);
    } catch (error) {
      console.error("Error updating tasks:", error);
    }
  };

  const handleViewAttachment = (attachment: { url: string, metadata: { fileName: string } }) => {
    setSelectedAttachment(attachment);
  };

  const renderAttachment = (attachment: { url: string, metadata: { fileName: string, fileSize: number, contentType: string } }, index: number) => {
    const isImage = attachment.metadata.contentType.startsWith('image/');

    return (
      <Box 
        key={index} 
        sx={{ 
          position: 'relative',
          boxShadow: 3,
          borderRadius: 2,
          overflow: 'hidden',
          width: 100,
          height: 100,
          display: 'inline-block',
          margin: 1,
          cursor: 'pointer',
          '&:hover .attachment-actions': {
            opacity: 1,
          },
        }}
        onClick={() => window.open(attachment.url, '_blank')}
      >
        {isImage ? (
          <img 
            src={attachment.url}
            alt={attachment.metadata.fileName}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f0f0f0',
            }}
          >
            <InsertDriveFileIcon sx={{ fontSize: 40, color: '#757575' }} />
            <Typography variant="caption" align="center" sx={{ mt: 1, px: 1, wordBreak: 'break-word' }}>
              {attachment.metadata.fileName.length > 10 ? attachment.metadata.fileName.substring(0, 10) + '...' : attachment.metadata.fileName}
            </Typography>
          </Box>
        )}
        <Box
          className="attachment-actions"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.2s',
          }}
        >
          <IconButton
            size="small"
            sx={{ 
              bgcolor: 'rgba(255,255,255,0.7)',
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleViewAttachment(attachment);
            }}
          >
            <VisibilityIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            sx={{ 
              bgcolor: 'rgba(255,255,255,0.7)',
              '&:hover': { bgcolor: 'rgba(255,255,255,0.9)' },
              ml: 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveAttachment(index);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderHeaderContent = () => (
    <Box display="flex" flexDirection="column" mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <Box display="flex" alignItems="flex-start">
          <Avatar src={creator?.avatarUrl} alt={creator?.firstName} sx={{ mr: 2 }} />
          <Box>
            <Typography variant="subtitle1">
              Created by: {creator ? `${creator.firstName} ${creator.lastName}` : 'Unknown'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Created on: {formatEditTime(issue.createdOn)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Last updated: {formatEditTime(issue.lastUpdated)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          {isEditing ? (
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="assigned-to-label">Assigned To</InputLabel>
              <Select
                labelId="assigned-to-label"
                id="assignedTo"
                name="assignedTo"
                multiple
                value={editedIssue.assignedTo || []}
                onChange={handleInputChange}
                label="Assigned To"
                disabled={!canEdit}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as string[]).map((value) => {
                      const user = users.find(u => u.userId === value);
                      return user ? (
                        <Chip
                          key={value}
                          avatar={<Avatar alt={user.firstName} src={user.avatarUrl} />}
                          label={`${user.firstName} ${user.lastName}`}
                        />
                      ) : null;
                    })}
                  </Box>
                )}
              >
                {users.map((user) => (
                  <MenuItem key={user.userId} value={user.userId}>
                    <Avatar src={user.avatarUrl} alt={user.firstName} sx={{ width: 24, height: 24, mr: 1 }} />
                    {`${user.firstName} ${user.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box>
              <Typography variant="body2">Assigned to:</Typography>
              <Box display="flex" alignItems="center">
                <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: '0.75rem' } }}>
                  {assignedUsers.map((user) => (
                    <Avatar key={user.userId} alt={user.firstName} src={user.avatarUrl} />
                  ))}
                </AvatarGroup>
                <Box ml={1}>
                  {assignedUsers.map((user, index) => (
                    <React.Fragment key={user.userId}>
                      <Typography variant="body2" component="span">
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                      {index < assignedUsers.length - 1 && <span>, </span>}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
            Project:
          </Typography>
          {isEditing ? (
            <FormControl fullWidth>
              <InputLabel id="project-label">Project</InputLabel>
              <Select
                labelId="project-label"
                id="projectId"
                name="projectId"
                value={editedIssue.projectId || ''}
                onChange={handleInputChange}
                label="Project"
                disabled={!canEdit}
              >
                {activeProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2">
              {activeProjects.find(p => p.id === editedIssue.projectId)?.name || 'Not assigned'}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );

  const handleDuplicateClick = () => {
    setIsDuplicateDialogOpen(true);
  };

  const handleDuplicateOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setDuplicateOptions(prev => ({ ...prev, [name]: checked }));
  };

  const handleDuplicateSelectionChange = (field: string, value: any) => {
    setDuplicateSelections(prev => ({ ...prev, [field]: value }));
  };

  const handleDuplicateConfirm = async () => {
    setIsDuplicating(true);
    try {
      const newIssue: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'> = {
        title: duplicateTitle,
        description: duplicateOptions.description ? issue.description : '',
        type: duplicateOptions.selections ? issue.type : duplicateSelections.type,
        status: duplicateOptions.selections ? issue.status : duplicateSelections.status,
        level: duplicateOptions.selections ? issue.level : duplicateSelections.level,
        assignedTo: duplicateOptions.selections ? issue.assignedTo : duplicateSelections.assignedTo,
        projectId: duplicateOptions.selections ? issue.projectId : duplicateSelections.projectId,
        conversations: [],
        attachments: duplicateOptions.attachments ? [...issue.attachments] : [],
        tasks: duplicateOptions.subTasks ? issue.tasks.map(task => ({ ...task, completed: false })) : [],
        tags: duplicateOptions.selections ? issue.tags : duplicateSelections.tags,
        isDeleted: false,
        createdBy: currentUser.userId || '',
      };
  
      const duplicatedIssue = await onDuplicateIssue(newIssue);
      setNewIssueId(duplicatedIssue.id);
      setDuplicateSuccess(true);
    } catch (error) {
      console.error('Error duplicating issue:', error);
      showSnackbar('Failed to duplicate issue', 'error');
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleGoToNewIssue = () => {
    if (newIssueId) {
      const url = `/issues/?project=${editedIssue.projectId}&issue=${newIssueId}`;
      window.location.href = url; // Use window.location.href for a full page redirect
    }
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleShareClick = () => {
    const url = `${window.location.origin}${window.location.pathname}?project=${editedIssue.projectId}&issue=${editedIssue.id}`;
    navigator.clipboard.writeText(url);
    showSnackbar('Link copied to clipboard', 'success');
  };

  const handleHistoryClick = async () => {
    try {
      const updates = await issueService.getIssueUpdates(issue.id);
      setUpdateHistory(updates);
      setIsHistoryOpen(true);
    } catch (error) {
      console.error("Error fetching issue updates:", error);
      showSnackbar('Failed to fetch update history', 'error');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ position: 'relative', p: 0 }}>
      <Box 
        display="flex" 
        flexDirection="column"
        position={isHeaderSticky ? 'sticky' : 'static'}
        top={0}
        zIndex={1000}
        bgcolor="background.paper"
        sx={{
          width: '100%',
          boxShadow: isHeaderSticky ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
        }}
      >
        {/* Gradient bar */}
        <Box
          sx={{
            height: '12px',
            background: 'linear-gradient(90deg, #4888c8 0%, #d24dba 50%, #1e4780 100%)',
            width: '100%',
          }}
        />

        {/* Header content */}
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="flex-start" 
          py={2}
          px={3}
        >
          <Box display="flex" alignItems="center" flexGrow={1} mr={2}>
            <IconButton 
              onClick={handlePinClick} 
              sx={{ 
                mr: 1,
                color: isHeaderSticky ? '#3684dd' : 'inherit',
                transform: isHeaderSticky ? 'rotate(45deg)' : 'none',
                transition: 'transform 0.3s, color 0.3s',
              }}
            >
              <PushPinOutlinedIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              {isEditing ? (
                <TextField
                  fullWidth
                  name="title"
                  value={editedIssue.title}
                  onChange={handleInputChange}
                  disabled={!canEdit}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    style: { 
                      fontSize: '1.5rem', 
                      fontWeight: 700,
                      lineHeight: '1.2',
                      padding: '0',
                      borderBottom: '1px solid #ccc',
                      color: '#4a5d87',
                    }
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      padding: '0',
                    },
                    '& .MuiInputBase-input': {
                      padding: '0',
                    },
                  }}
                />
              ) : (
                <Typography variant="h5" sx={{ wordBreak: 'break-word', fontWeight: 700, color: '#4a5d87' }}>
                  {editedIssue.title}
                </Typography>
              )}
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" color="text.secondary">
                  Issue ID: {issue.id}
                </Typography>
                <IconButton onClick={handleShareClick} size="small" sx={{ ml: 1 }}>
                  <LinkIcon fontSize="small" sx={{ color: 'rgb(25, 118, 210)' }} />
                </IconButton>
                <IconButton onClick={handleHistoryClick} size="small" sx={{ ml: 1 }}>
                  <HistoryOutlinedIcon fontSize="small" sx={{ color: 'rgb(25, 118, 210)' }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            {isEditing ? (
              <>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="type-label">Type</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    name="type"
                    value={editedIssue.type}
                    onChange={handleInputChange}
                    label="Type"
                  >
                    <MenuItem value="Issue">Issue</MenuItem>
                    <MenuItem value="Enhancement">Enhancement</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="level-label">Level</InputLabel>
                  <Select
                    labelId="level-label"
                    id="level"
                    name="level"
                    value={editedIssue.level}
                    onChange={handleInputChange}
                    label="Level"
                  >
                    <MenuItem value="Design">Design</MenuItem>
                    <MenuItem value="Functionality">Functionality</MenuItem>
                    <MenuItem value="Backend">Backend</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    name="status"
                    value={editedIssue.status}
                    onChange={handleInputChange}
                    label="Status"
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="In Process">In Process</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
                <IconButton onClick={handleSubmit} color="primary" disabled={isSubmitting}>
                  <CheckIcon />
                </IconButton>
                <Button onClick={handleCancel} color="secondary">
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Chip label={`Type: ${editedIssue.type}`} style={{ backgroundColor: getChipColor(editedIssue.type) }} />
                <Chip label={`Level: ${editedIssue.level}`} style={{ backgroundColor: getChipColor(editedIssue.level) }} />
                <Chip label={`Status: ${editedIssue.status}`} style={{ backgroundColor: getChipColor(editedIssue.status) }} />
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  {canEdit && (
                    <IconButton onClick={() => setIsEditing(true)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ p: 3 }}>
        {renderHeaderContent()}
        <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 1 }}>
          <Card elevation={3} sx={{ mb: 2, bgcolor: '#FFFAE6', position: 'relative' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#FFFAE6',
                  zIndex: 1,
                  py: 1,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    color: '#333',
                    display: 'inline-block',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      bottom: '-4px',
                      height: '2px',
                      backgroundColor: '#FFD700',
                    },
                  }}
                >
                  Description
                </Typography>
                <Button
                  onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                  endIcon={descriptionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  sx={{ color: 'text.secondary' }}
                >
                  {descriptionExpanded ? 'Hide' : 'Show'}
                </Button>
              </Box>
              {descriptionExpanded && (
                <>
                  {isEditing ? (
                    <Editor
                      init={initTinyMCE as any}
                      value={editedIssue.description}
                      onEditorChange={handleEditorChange}
                      disabled={!canEdit}
                    />
                  ) : (
                    <div 
                      className="tinymce-content"
                      dangerouslySetInnerHTML={{ __html: editedIssue.description }} 
                    />
                  )}
                </>
              )}
            </CardContent>
          </Card>

          <Card elevation={3} sx={{ mb: 2, bgcolor: '#d7fced' }}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="subtitle1" sx={{ mr: 2 }}>
                  Sub Tasks ({tasks.filter(task => task.completed).length}/{tasks.length} completed)
                </Typography>
                {tasks.length > 0 && (
                  <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={(tasks.filter(task => task.completed).length / tasks.length) * 100}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: '#e0e0e0',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        border: '1px solid #bdbdbd',
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 5,
                          backgroundColor: '#4caf50',
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="tasks">
                  {(provided: DroppableProvided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {tasks.map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id} index={index}>
                          {(provided: DraggableProvided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              disablePadding
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover .task-actions': {
                                  opacity: 1,
                                },
                              }}
                            >
                              <ListItemIcon>
                                <DragIndicatorIcon />
                              </ListItemIcon>
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={task.completed}
                                  onChange={() => handleToggleTask(task.id)}
                                  disabled={!canEdit}
                                />
                              </ListItemIcon>
                              {editingTaskId === task.id ? (
                                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                  <TextField
                                    fullWidth
                                    value={editedTaskText}
                                    onChange={(e) => setEditedTaskText(e.target.value)}
                                    onBlur={() => handleSaveEditedTask(task.id)}
                                    onKeyPress={(e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSaveEditedTask(task.id);
                                      }
                                    }}
                                    autoFocus
                                  />
                                  <IconButton onClick={() => handleSaveEditedTask(task.id)}>
                                    <CheckIcon />
                                  </IconButton>
                                  <IconButton onClick={() => setEditingTaskId(null)}>
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              ) : (
                                <>
                                  <ListItemText
                                    primary={task.text}
                                    sx={{ 
                                      textDecoration: task.completed ? 'line-through' : 'none',
                                      flexGrow: 1,
                                    }}
                                  />
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {(task.editedAt || task.completedOn) && (
                                      <Tooltip title={`${task.editedAt ? `Edited on ${new Date(task.editedAt).toLocaleString()}` : ''}
                                                ${task.completedOn ? `\nCompleted on ${new Date(task.completedOn).toLocaleString()}` : ''}`}>
                                        <HistoryIcon sx={{ mr: 1 }} />
                                      </Tooltip>
                                    )}
                                    <Box 
                                      className="task-actions" 
                                      sx={{ 
                                        opacity: 0, 
                                        transition: 'opacity 0.2s',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEditTask(task.id, task.text)}>
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Copy">
                                        <IconButton onClick={() => handleCopyTask(task.text)}>
                                          <CopyIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton onClick={() => handleDeleteTask(task.id)}>
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
              <Box display="flex" alignItems="center" mt={2}>
                <TextField
                  fullWidth
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  placeholder="Type task"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTask();
                    }
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddTask}
                  sx={{ ml: 1, whiteSpace: 'nowrap' }}
                >
                  Add Task
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card elevation={3} sx={{ mb: 2, bgcolor: 'white' }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>Comments</Typography>
              <Box>
                {editedIssue.conversations.map((conversation) => {
                  const user = users.find(u => u.userId === conversation.userId) || currentUser;
                  return (
                    <Box 
                      key={conversation.id} 
                      data-comment-id={conversation.id}
                      sx={{ display: 'flex', mb: 2, alignItems: 'flex-start', position: 'relative' }}
                    >
                      <Avatar 
                        src={user?.avatarUrl || "https://via.placeholder.com/40"} 
                        alt={user?.firstName} 
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <Paper 
                        elevation={1} 
                        sx={{ 
                          p: 2, 
                          flex: 1, 
                          bgcolor: '#E3F2FD',
                          borderRadius: 2,
                          position: 'relative',
                          '&:hover .edit-icon': {
                            opacity: 1,
                          },
                        }}
                      >
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                            {user.firstName} {user.lastName}
                            {conversation.editedAt && (
                              <Typography component="span" variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                                (edited on {formatEditTime(conversation.editedAt)})
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                        {editingCommentId === conversation.id ? (
                          <>
                            <Editor
                              init={{...(initTinyMCE as any), height: 150}}
                              value={editedCommentContent}
                              onEditorChange={(content) => setEditedCommentContent(content)}
                            />
                            <Box sx={{ mt: 1 }}>
                              <Button onClick={() => handleSaveEditedComment(conversation.id)} variant="contained" size="small">
                                Save
                              </Button>
                              <Button onClick={() => setEditingCommentId(null)} variant="outlined" size="small" sx={{ ml: 1 }}>
                                Cancel
                              </Button>
                            </Box>
                          </>
                        ) : (
                          <div 
                            className="tinymce-content"
                            dangerouslySetInnerHTML={{ __html: conversation.message }} 
                          />
                        )}
                        <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mt: 1 }}>
                          {formatEditTime(conversation.timestamp)}
                        </Typography>
                        {conversation.userId === currentUser.userId && editingCommentId !== conversation.id && (
                          <IconButton
                            className="edit-icon"
                            onClick={() => handleEditComment(conversation.id, conversation.message)}
                            sx={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              opacity: 0,
                              transition: 'opacity 0.2s',
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Paper>
                    </Box>
                  );
                })}
              </Box>
            </CardContent>
            <CardActions sx={{ flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                <Editor
                  init={{ ...(initTinyMCE as any), height: 200 }}
                  value={newComment}
                  onEditorChange={handleCommentEditorChange}
                />
              </Box>
              <Button 
                onClick={handleAddComment} 
                variant="contained" 
                color="primary" 
                sx={{ mt: 2, alignSelf: 'flex-end' }}
              >
                Add Comment
              </Button>
            </CardActions>
          </Card>

          <Card elevation={3} sx={{ mb: 2, bgcolor: 'white' }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>Attachments</Typography>
              <Box display="flex" flexWrap="wrap" gap={2}>
                {attachments.map((attachment, index) => renderAttachment(attachment, index))}
                {uploadingAttachments.map((file, index) => (
                  <Box
                  key={`uploading-${index}`}
                  sx={{
                    position: 'relative',
                    boxShadow: 3,
                    borderRadius: 2,
                    overflow: 'hidden',
                    width: 100,
                    height: 100,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 1,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <CircularProgress />
                  <Typography variant="caption" sx={{ position: 'absolute', bottom: 4, left: 4, right: 4, textAlign: 'center' }}>
                    {file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box mt={2}>
              <FileUploadArea
                onUpload={handleFileUpload}
                multiple={true}
              />
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>Tags</Typography>
          {isEditing ? (
            <Autocomplete
              multiple
              id="tags"
              options={allTags}
              freeSolo
              value={editedIssue.tags || []}
              onChange={handleTagChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Tags"
                  placeholder="Add tags"
                />
              )}
              renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          ) : (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {editedIssue.tags && editedIssue.tags.length > 0 ? (
                editedIssue.tags.map((tag) => (
                  <Chip key={tag} label={tag} />
                ))
              ) : (
                <Typography variant="body2">No tags</Typography>
              )}
            </Box>
          )}
        </Box>

        {!isEditing && canEdit && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<DuplicateIcon />}
              onClick={handleDuplicateClick}
            >
              Duplicate
            </Button>
            <Box>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setIsEditing(true)}
                sx={{ mr: 2 }}
              >
                Edit Issue
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteIssue}
              >
                Delete Issue
              </Button>
            </Box>
          </Box>
        )}

        {isEditing && canEdit && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
            <Button 
              variant="outlined" 
              color="secondary" 
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update Issue'}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
    {showCongratulations && (
      <CongratulationsLightbox onClose={() => setShowCongratulations(false)} />
    )}

    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Confirm ${deleteType === 'task' ? 'Task' : deleteType === 'issue' ? 'Issue' : 'Attachment'} Deletion`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this {deleteType}? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteConfirm} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog open={isDuplicateDialogOpen} onClose={() => setIsDuplicateDialogOpen(false)}>
      {!duplicateSuccess ? (
        <>
          <DialogTitle>Duplicate Issue</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a title for the duplicated issue and select which elements to include:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Issue Title"
              type="text"
              fullWidth
              value={duplicateTitle}
              onChange={(e) => setDuplicateTitle(e.target.value)}
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={duplicateOptions.description} onChange={handleDuplicateOptionChange} name="description" />}
                label="Include description"
              />
              <FormControlLabel
                control={<Checkbox checked={duplicateOptions.selections} onChange={handleDuplicateOptionChange} name="selections" />}
                label="Include all selections (type, status, level, assignees, project, tags)"
              />
              <FormControlLabel
                control={<Checkbox checked={duplicateOptions.attachments} onChange={handleDuplicateOptionChange} name="attachments" />}
                label="Include attachments"
              />
              <FormControlLabel
                control={<Checkbox checked={duplicateOptions.subTasks} onChange={handleDuplicateOptionChange} name="subTasks" />}
                label="Include sub-tasks"
              />
            </FormGroup>

            {!duplicateOptions.selections && (
              <Box mt={2}>
                <Typography variant="subtitle1">Custom Selections:</Typography>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="duplicate-type-label">Type</InputLabel>
                  <Select
                    labelId="duplicate-type-label"
                    value={duplicateSelections.type}
                    onChange={(e) => handleDuplicateSelectionChange('type', e.target.value)}
                    label="Type"
                  >
                    <MenuItem value="Issue">Issue</MenuItem>
                    <MenuItem value="Enhancement">Enhancement</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="duplicate-status-label">Status</InputLabel>
                  <Select
                    labelId="duplicate-status-label"
                    value={duplicateSelections.status}
                    onChange={(e) => handleDuplicateSelectionChange('status', e.target.value)}
                    label="Status"
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="In Process">In Process</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="duplicate-level-label">Level</InputLabel>
                  <Select
                    labelId="duplicate-level-label"
                    value={duplicateSelections.level}
                    onChange={(e) => handleDuplicateSelectionChange('level', e.target.value)}
                    label="Level"
                  >
                    <MenuItem value="Blocking">Blocking</MenuItem>
                    <MenuItem value="Not Blocking">Not Blocking</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="duplicate-assigned-to-label">Assigned To</InputLabel>
                  <Select
                    labelId="duplicate-assigned-to-label"
                    multiple
                    value={duplicateSelections.assignedTo}
                    onChange={(e) => handleDuplicateSelectionChange('assignedTo', e.target.value)}
                    label="Assigned To"
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {(selected as string[]).map((value) => {
                          const user = users.find(u => u.userId === value);
                          return user ? (
                            <Chip key={value} label={`${user.firstName} ${user.lastName}`} />
                          ) : null;
                        })}
                      </Box>
                    )}
                  >
                    {users.map((user) => (
                      <MenuItem key={user.userId} value={user.userId}>
                        {`${user.firstName} ${user.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="duplicate-project-label">Project</InputLabel>
                  <Select
                    labelId="duplicate-project-label"
                    value={duplicateSelections.projectId}
                    onChange={(e) => handleDuplicateSelectionChange('projectId', e.target.value)}
                    label="Project"
                  >
                    {activeProjects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Autocomplete
                  multiple
                  freeSolo
                  options={allTags}
                  value={duplicateSelections.tags}
                  onChange={(_, newValue: Array<string>) => {
                    const updatedTags = newValue.map(tag => tag.trim());
                    handleDuplicateSelectionChange('tags', updatedTags);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const input = event.target as HTMLInputElement;
                      const newTag = input.value.trim();
                      if (newTag && !duplicateSelections.tags.includes(newTag)) {
                        handleDuplicateSelectionChange('tags', [...duplicateSelections.tags, newTag]);
                        input.value = '';
                      }
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label="Tags" margin="dense" />}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDuplicateDialogOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleDuplicateConfirm} 
              variant="contained" 
              color="primary"
              disabled={isDuplicating}
            >
              {isDuplicating ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Duplicate'
              )}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle sx={{ backgroundColor: '#f0f7ff', color: '#0066cc', fontWeight: 'bold' }}>
            Success!
          </DialogTitle>
          <DialogContent sx={{ width: '400px', padding: '24px' }}>
            <Typography variant="body1" sx={{ marginBottom: '16px' }}>
              You Duplicated: <strong>{issue.title}</strong>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px' }}>
              New Task/Issue: <strong>{duplicateTitle}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              id: {newIssueId}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: '16px 24px' }}>
            <Button 
              onClick={handleGoToNewIssue} 
              variant="contained" 
              color="primary"
              sx={{ 
                backgroundColor: '#0066cc',
                '&:hover': {
                  backgroundColor: '#004999',
                },
              }}
            >
              GO TO NEW ISSUE
            </Button>
            <Button 
              onClick={() => setIsDuplicateDialogOpen(false)} 
              color="primary"
              sx={{ color: '#0066cc' }}
            >
              STAY HERE
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>

    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>

    <UpdateHistoryLightbox
      open={isHistoryOpen}
      onClose={() => setIsHistoryOpen(false)}
      updates={updateHistory}
      isLoading={false}
    />

    <ImageDialog
      open={!!selectedAttachment}
      onClose={() => setSelectedAttachment(null)}
      maxWidth={false}
    >
      <ImageDialogContent sx={{ p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {selectedAttachment && (
          <img
            src={selectedAttachment.url}
            alt={selectedAttachment.metadata.fileName}
            style={{
              maxWidth: '100%',
              maxHeight: '90vh',
              objectFit: 'contain',
            }}
          />
        )}
      </ImageDialogContent>
    </ImageDialog>
  </Box>
);
};

export default IssueDetails;